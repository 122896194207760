<template>
    <!-- group: { selector: 'maChuyenDi', desc: false }, -->
    <DxList
        :data-source="{
            store: DanhSachVe,
        }"
        class="bao-cao-ban-ve"
        height="100%"
        :hoverStateEnabled="false"
        :focusStateEnabled="false"
        :activeStateEnabled="false"
    >
        <template #item="{ data: item }">
            <ItemVeVue :item="item" :query="query" />
        </template>
        <!-- :collapsible-groups="true"
        :grouped="true"
        <template #group="{ data: item }">
            <div class="font-16 font-medium" @click="onItemClick(item)">
                {{ item.key ? $Helper.formatDate(item.key) : "Ngày" }}
            </div>
        </template> -->
    </DxList>
</template>

<script>
import { DxList } from "devextreme-vue";
import ItemVeVue from "./ItemVe.vue";
export default {
    components: {
        DxList,
        ItemVeVue,
    },
    data() {
        return {
            DanhSachVe: [],
            query: {},
        };
    },
    computed: {},
    methods: {
        async LayDanhSachVe() {
            try {
                console.log("abcd");
                this.DanhSachVe = (
                    await this.$store.dispatch(
                        "BaoCao/Get_BaoCaoHanhKhachMuaVeTheoChuyenDiCuaLaiXe",
                        this.query.IdChuyenDi,
                    )
                ).Store;
            } catch (error) {
                console.log("🚀 ~ error", error);
            }
        },
    },
    created() {
        let query = this.$route.query;
        if (!query.IdChuyenDi) {
            this.$Helper.ThongBaoToast(
                "error",
                "Không tìm thấy thông tin bán vé! Chuyến đi không hợp lệ.",
            );
            this.$router.go(-1);
        } else {
            this.query = query;
            console.log("🚀 ~ onIonViewWillEnter ~ this.query:", this.query);
            this.LayDanhSachVe();
        }
    },
};
</script>

<style>
.bao-cao-ban-ve .dx-item.dx-list-item {
    overflow: unset;
    margin-bottom: 12px;
}
.bao-cao-ban-ve .dx-list-group-body {
    max-height: calc(100vh - 36px - 36px - 48px - 44px - 40px);
    overflow: auto;
}
.bao-cao-ban-ve .dx-list-group-header {
    color: #fb8c00;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    flex-direction: row-reverse;
    padding: 8px 8px 4px;
    border: 1px solid #dadce0;
    border-radius: 4px;
}
</style>
